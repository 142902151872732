<template>
	<div>
		<b-row>
			<b-col cols="12">
				<statistic-card-horizontal
					icon="UserIcon"
					color="info"
					:statistic="providerName"
					statistic-title="Proveedor"
				/>
			</b-col>

			<b-col cols="12">
				<b-card class="mb-1">
					<b-form>
						<b-row>
							<b-col cols="12 mb-2">
								<h5>{{ indicatorText.toUpperCase() }}</h5>
							</b-col>
							<b-col cols="12 mb-2">
								<b-table
									ref="refResumeTable"
									:items="resumenTable.items"
									:fields="resumenTable.fields"
									show-empty
									empty-text="No se encontraron resultados"
									responsive
									striped
								></b-table>
							</b-col>

							<b-col cols="12 mb-2">
								<h5>COMENTARIOS</h5>
							</b-col>
							<b-col cols="12 mb-1">
								<b-form-group>
									<label for="productivity">
										<strong>Productividad</strong>
									</label>

									<b-form-textarea
										id="productivity"
										v-model="documentData.productivity"
										placeholder="Ingrese texto aquí"
										rows="2"
									/>
								</b-form-group>
							</b-col>

							<b-col cols="12 mb-1">
								<b-form-group>
									<label for="security">
										<strong>Seguridad</strong>
									</label>

									<b-form-textarea
										id="security"
										v-model="documentData.security"
										placeholder="Ingrese texto aquí"
										rows="2"
									/>
								</b-form-group>
							</b-col>

							<b-col cols="12 mb-1">
								<b-form-group>
									<label for="labor">
										<strong>Laboral</strong>
									</label>

									<b-form-textarea
										id="labor"
										v-model="documentData.labor"
										placeholder="Ingrese texto aquí"
										rows="2"
									/>
								</b-form-group>
							</b-col>

							<b-col cols="12 mb-1">
								<b-form-group>
									<label for="colpa">
										<strong>Colpa</strong>
									</label>

									<b-form-textarea
										id="colpa"
										v-model="documentData.colpa"
										placeholder="Ingrese texto aquí"
										rows="2"
									/>
								</b-form-group>
							</b-col>

							<b-col cols="12 mb-1">
								<b-form-group>
									<label for="financial">
										<strong>Financiero</strong>
									</label>

									<b-form-textarea
										id="financial"
										v-model="documentData.financial"
										placeholder="Ingrese texto aquí"
										rows="2"
									/>
								</b-form-group>
							</b-col>

							<b-col cols="12 mb-1">
								<b-form-group>
									<label for="rotation">
										<strong>Rotación</strong>
									</label>

									<b-form-textarea
										id="rotation"
										v-model="documentData.rotation"
										placeholder="Ingrese texto aquí"
										rows="2"
									/>
								</b-form-group>
							</b-col>

							<b-col cols="12 mb-1">
								<b-form-group>
									<label for="penality">
										<strong>Penalidades</strong>
									</label>

									<b-form-textarea
										id="penality"
										v-model="documentData.penality"
										placeholder="Ingrese texto aquí"
										rows="2"
									/>
								</b-form-group>
							</b-col>

							<b-col cols="12">
								<b-form-group>
									<label for="conclusion">
										<strong>Extra</strong>
									</label>

									<b-form-textarea
										id="conclusion"
										v-model="documentData.conclusion"
										placeholder="Ingrese texto aquí"
										rows="2"
									/>
								</b-form-group>
							</b-col>
						</b-row>
					</b-form>
				</b-card>
			</b-col>

			<b-col cols="12">
				<b-button-loading
					text="GENERAR REPORTE PDF"
					variant="outline-primary"
					@process-action="generateDocument"
					:processing="processing"
					:block="true"
					size="lg"
				/>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { ref, onBeforeMount, onUnmounted } from "@vue/composition-api"
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import performanceStoreModule from "../performanceStoreModule"
import useNotifications from "@notifications"
import Ripple from "vue-ripple-directive"
import VueSweetalert2 from "vue-sweetalert2"
import store from "@/store"
import Vue from "vue"
import "animate.css"
import router from "@/router"
import axios from "@axios"

Vue.use(VueSweetalert2)

export default {
	components: {
		BButtonLoading,
		StatisticCardHorizontal
	},
	directives: {
		Ripple
	},
	setup() {
		const { swalNotification, toastNotification } = useNotifications()

		const processing = ref(false)
		const refResumenTable = ref(null)
		const providerName = ref("Proveedor")
		const indicatorText = ref("")
		const resumenTable = ref({
			fields: [],
			items: []
		})

		const documentData = ref({
			document_provider_id: null,
			productivity: null,
			security: null,
			labor: null,
			colpa: null,
			financial: null,
			rotation: null,
			penality: null,
			conclusion: null,
			file_path: null
		})

		const PERFORMANCE_APP_STORE_MODULE_NAME = "app-performance"

		// REGISTER MODULE
		if (!store.hasModule(PERFORMANCE_APP_STORE_MODULE_NAME))
			store.registerModule(
				PERFORMANCE_APP_STORE_MODULE_NAME,
				performanceStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(PERFORMANCE_APP_STORE_MODULE_NAME))
				store.unregisterModule(PERFORMANCE_APP_STORE_MODULE_NAME)
		})

		store
			.dispatch("app-performance/fetchPerformance", {
				id: router.currentRoute.params.id
			})
			.then((response) => {
				const { performance } = response.data

				documentData.value = {
					productivity: performance.document_provider?.productivity || null,
					security: performance.document_provider?.security || null,
					labor: performance.document_provider?.labor || null,
					colpa: performance.document_provider?.colpa || null,
					financial: performance.document_provider?.financial || null,
					rotation: performance.document_provider?.rotation || null,
					penality: performance.document_provider?.penality || null,
					conclusion: performance.document_provider?.conclusion || null,
					document_provider_id: performance.document_provider?.id || null
				}

				indicatorText.value = performance.indicator
				providerName.value =
					performance.provider.alias || performance.provider.name

				resumenTable.value.fields = performance.table.fields
				resumenTable.value.items = performance.table.items
			})
			.catch((error) => {
				router.push({ name: "performances-list" })
				toastNotification(
					error.response?.data?.message ||
						"Ocurrió un error al obtener los datos",
					false
				)
			})

		const generateDocument = () => {
			processing.value = true

			Vue.swal({
				title: "¿Está seguro(a) de generar el reporte?",
				html: "<small>¡Si no lo está, puede cancelar la acción!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, generar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			}).then((result) => {
				if (!result.value) {
					processing.value = false
					return
				}

				const payload = { ...documentData.value }

				store
					.dispatch("app-performance/generatePerformanceDocument", {
						id: router.currentRoute.params.id,
						payload
					})
					.then((response) => {
						router.push({ name: "performances-list" })
						swalNotification(response.data.message)
					})
					.catch((error) => {
						const message =
							error.response.data.message || "Error al generar el carta"
						swalNotification(message, false)
					})
					.finally(() => {
						processing.value = false
					})
			})
		}

		return {
			documentData,
			processing,
			providerName,
			indicatorText,

			refResumenTable,
			resumenTable,

			generateDocument
		}
	}
}
</script>

<style>
.table-custom-red {
	background-color: #ff0000 !important;
	color: #fff !important;
}

.table-custom-yellow {
	background-color: #ffff00 !important;
	color: #000 !important;
}

.table-custom-green {
	background-color: #92d050 !important;
	color: #000 !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>